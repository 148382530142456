import React from "react";
import CustomTable from "../../components/customtable/CustomTable";

const QuatationTable = ({ quotation }) => {
  const ColumnHeaders = () => {
    return (
      <>
        <th scope="col" className="table_responsive_text">
          Client name
        </th>
        <th scope="col" className="table_responsive_text">
          Company
        </th>
        <th scope="col" className="table_responsive_text">
          Amount
        </th>
      </>
    );
  };
  const DataRows = () => (
    <>
      {(quotation || [])?.map((item, index) => (
        <tr key={item.id} className="font-Proxima shadow-sm ">
          <td className="px-5 py-3 truncate">{item?.name}</td>
          <td className="px-5 py-3 truncate">{item?.company_name}</td>
          <td className="px-5 py-3 truncate">
            {Number(item?.amount).toFixed(2)}
          </td>
        </tr>
      ))}
    </>
  );
  return (
    <div>
      <div>
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={quotation}
          loading={false}
          showPagination={false}
        />
      </div>
    </div>
  );
};

export default QuatationTable;
