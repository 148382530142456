import React, { useEffect, useState } from "react";
import { DefaultUser } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import {
  DefaultButton,
  DefaultPrimaryButton,
  InputError,
  InputWithLabel,
  SelectBox,
} from "../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModel from "../../common/ConfirmationModel";
import { useLocation, useNavigate } from "react-router-dom";
import { createCustomers, getCustomer } from "../../redux/customer/slice";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../redux/address/slice";

const CreateEditCustomer = () => {
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const location = useLocation();
  const isEditMode = location?.state?.mode;
  const customerData = location?.state?.customer;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //  Selector
  const { getCustomerData, countryData, countryLoading } = useSelector(
    (store) => ({
      getCustomerData: store?.customerData?.getCustomerStatus?.data?.data,

      //Contry Data
      countryData: store?.addressData?.getCountryDataStatus?.data,
      countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    })
  );
  // Get country
  useEffect(() => {
    if (!countryData?.length) {
      dispatch(getCountryData());
    }
  }, [dispatch]);

  // get customer
  useEffect(() => {
    if (customerData?.id) {
      dispatch(getCustomer(customerData?.id));
    }
  }, [customerData, dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({
        ...x,
        label: `+${x?.id}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);

  // Submit Customer
  const handleConfirm = async () => {
    setIsLoading(true);
    const payload = {
      name: formValues?.fullName,
      company_name: formValues?.company,
      email: formValues?.email,
      phone: formValues?.mobile_number,
      phone_code: formValues?.phone_codeId,
      office_code: formValues?.office_codeId,
      office_number: formValues?.office_number,
      address: formValues?.address,
      postal_code: formValues?.postalcode,
    };
    const action = customerData?.id
      ? createCustomers({ id: customerData.id, ...payload })
      : createCustomers(payload);
    try {
      const res = await dispatch(action);
      if (res.type === "createCustomers/fulfilled") {
        navigate("/customer");
      }
    } catch (error) {
      console.error("Error creating customer", error);
    } finally {
      setIsLoading(false);
      setShowConfirmationModal(false);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex-jb-ic ">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <h6 className="main-title">Customers</h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbParent2={"Customers"}
            breadCrumbActive={"Create Customers"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="w-full bg-white shadow drop-shadow-md rounded-lg py-5 px-6">
          <div className="py-4">
            <p className="md:text-xl font-normal">
              {isEditMode == "edit" ? "Edit Customer" : "Create New Customer"}
            </p>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullName: customerData?.id ? getCustomerData?.full_name : "",
              email: customerData?.id ? getCustomerData?.email : "",
              company: customerData?.id ? getCustomerData?.company_name : "",
              phone_code: customerData?.id ? getCustomerData?.phone_code : "",
              office_code: customerData?.id ? getCustomerData?.office_code : "",
              phone_codeId: customerData?.phone_code
                ? Number(getCustomerData?.phone_code)
                : "",
              office_codeId: customerData?.office_code
                ? Number(getCustomerData?.office_code)
                : "",
              address: customerData?.id ? getCustomerData?.address : "",
              postalcode: customerData?.id ? getCustomerData?.postal_code : "",
              office_number: customerData?.id
                ? getCustomerData?.office_number
                : "",
              mobile_number: customerData?.id ? getCustomerData?.phone : "",
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string()
                .trim()
                .min(3)
                .required("Please Enter Name"),
              email: Yup.string().min(3).required("Please Enter E-mail"),
              company: Yup.string()
                .trim()
                .min(2)
                .required("Please Enter Company"),
              address: Yup.string()
                .trim()
                .min(3)
                .required("Please Enter Address"),
              postalcode: Yup.string()
                .required("Please Enter Postal Code")
                .min(5, "Postal Code must be at least 5 characters")
                .matches(/^\d+$/, "Postal Code must be numeric"),
            })}
            onSubmit={(values) => {
              setFormValues(values);
              setShowConfirmationModal(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="sm:grid grid-cols-2 gap-6 mb-4">
                    {/* Name */}
                    <div className="sm:mb-0 mb-2">
                      <InputWithLabel
                        className={`${"input-css"}  ${
                          errors.fullName && touched.fullName
                            ? `border border-red`
                            : ""
                        }  `}
                        idFromName="fullName"
                        label="Name*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.fullName}
                        errors={errors.fullName && touched.fullName}
                        errorsText={errors.fullName}
                      />
                    </div>
                    {/* company */}
                    <div className="sm:mb-0 mb-4">
                      <InputWithLabel
                        className={`${"input-css"}  ${
                          errors.company && touched.company
                            ? `border border-red`
                            : ``
                        }  `}
                        idFromName="company"
                        label="Company*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.company}
                        errors={errors.company && touched.company}
                        errorsText={errors.company}
                      />
                    </div>
                    <div className="gap-3">
                      <div className="sm:grid grid-cols-3 gap-2">
                        {/* Country */}
                        {/* office code */}
                        <div className="sm:mb-0 mb-2">
                          <div
                            className={`${"text-black"}  ${
                              errors.office_codeId && touched.office_codeId
                                ? ` border-red border`
                                : ` `
                            } relative bg-white transition duration-300 rounded `}
                          >
                            <SelectBox
                              id="office_codeId"
                              placeholder="Country*"
                              isLoading={countryLoading}
                              options={countryArray}
                              getOptionLabel={(country) => country?.label}
                              getOptionValue={(country) => country?.id}
                              value={countryArray.find(
                                (country) =>
                                  country?.phonecode === values?.office_codeId
                              )}
                              onChange={(e) => {
                                setFieldValue("office_code", e);
                                setFieldValue("office_codeId", e?.phonecode);
                                setIsDisable(false);
                              }}
                            />
                            <label
                              htmlFor="country"
                              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Country*
                            </label>
                          </div>
                          {errors.office_codeId && touched.office_codeId ? (
                            <InputError errorTitle={errors.office_codeId} />
                          ) : null}
                        </div>

                        {/* Phone */}
                        <div className="col-span-2 sm:mb-0 mb-4">
                          <InputWithLabel
                            className={`${"input-css"}  ${
                              errors.office_number && touched.office_number
                                ? `border border-red`
                                : ``
                            }  `}
                            idFromName="office_number"
                            label="Office Number*"
                            type="numeric"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                handleChange(e);
                                setIsDisable(false);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.office_number}
                            errors={
                              errors.office_number && touched.office_number
                            }
                            errorsText={errors.office_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid grid-cols-3 gap-2">
                      {/* Country */}
                      <div className="sm:mb-0 mb-2">
                        <div
                          className={`${"text-black hover:border-themeBtn"}  ${
                            errors.phone_codeId && touched.phone_codeId
                              ? ` border-red border`
                              : ` border-borderTheme/80`
                          } relative bg-white transition duration-300 rounded `}
                        >
                          <SelectBox
                            id="phone_codeId"
                            placeholder="Country*"
                            isLoading={countryLoading}
                            options={countryArray}
                            getOptionLabel={(country) => country?.label}
                            getOptionValue={(country) => country?.id}
                            value={countryArray.find(
                              (country) =>
                                country?.phonecode === values?.phone_codeId
                            )}
                            onChange={(e) => {
                              setFieldValue("phone_code", e);
                              setFieldValue("phone_codeId", e?.phonecode);
                              setIsDisable(false);
                            }}
                          />
                          <label
                            htmlFor="country"
                            className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                          >
                            Country*
                          </label>
                        </div>
                        {errors.phone_codeId && touched.phone_codeId ? (
                          <InputError errorTitle={errors.phone_codeId} />
                        ) : null}
                      </div>
                      {/* Phone */}
                      <div className="col-span-2 sm:mb-0 mb-2">
                        <InputWithLabel
                          className={`${"input-css"}  ${
                            errors.mobile_number && touched.mobile_number
                              ? `border border-red`
                              : ``
                          }  `}
                          idFromName="mobile_number"
                          label="Mobile Number*"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              handleChange(e);
                              setIsDisable(false);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.mobile_number}
                          errors={errors.mobile_number && touched.mobile_number}
                          errorsText={errors.mobile_number}
                        />
                      </div>
                    </div>
                    <div className="sm:mb-0 mb-2">
                      {/* Email */}
                      <InputWithLabel
                        className={`${"input-css"}  ${
                          errors.email && touched.email
                            ? `border border-red`
                            : ``
                        }  `}
                        idFromName="email"
                        label="Email*"
                        type="email"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        errors={errors.email && touched.email}
                        errorsText={errors.email}
                      />
                    </div>
                    <div></div>
                    <div className="sm:mb-0 mb-2">
                      {/* Adddress */}
                      <InputWithLabel
                        className={`${"input-css"}  ${
                          errors.address && touched.address
                            ? `border border-red`
                            : ``
                        }  `}
                        idFromName="address"
                        label="Address*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.address}
                        errors={errors.address && touched.address}
                        errorsText={errors.address}
                      />
                    </div>
                    <div className="sm:mb-0 mb-2">
                      {/* postal code */}
                      <InputWithLabel
                        className={`${"input-css"}  ${
                          errors.postalcode && touched.postalcode
                            ? `border border-red`
                            : ``
                        }  `}
                        idFromName="postalcode"
                        label="Postal Code*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.postalcode}
                        errors={errors.postalcode && touched.postalcode}
                        errorsText={errors.postalcode}
                      />
                    </div>
                  </div>
                </div>
                {/* Submit button */}
                <div className="flex justify-end mt-3 gap-2">
                  <DefaultPrimaryButton
                    value="Cancel"
                    onClick={() => navigate("/customer")}
                  />
                  <DefaultButton
                    type="submit"
                    value={isEditMode === "edit" ? "Save" : "Create"}
                    disabled={isDisable}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModel
          isLoading={isLoading}
          confirmText={isEditMode === "edit" ? "Edit" : "Create"}
          onClose={(e) => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => handleConfirm()}
        />
      )}
    </>
  );
};
export default CreateEditCustomer;
