import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get sales List
export const getSalesList = createAsyncThunk(
  "getSalesList",
  async (
    {
      pageLimit,
      pageCount,
      sort_by,
      sort_direction,
      status,
      search,
      company_name,
      deposit_payment_method,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `sales?&sort_by=${sort_by}&sort_direction=${sort_direction}&page=${pageCount}&search=${search}&limit=${pageLimit}&company_name=${company_name}&deposit_payment_method=${deposit_payment_method}&status=${status}`
      );
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);
//Get Company List
export const getcompanyList = createAsyncThunk(
  "getcompanyList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("sales-companies");
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// Get sales
export const getOneSales = createAsyncThunk(
  "getOneSales",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`sales/${id}`);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Create Edit sales
export const createSales = createAsyncThunk(
  "createSales",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`sales`, data);
      toast.success(response.data.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Send Email
export const sendEmail = createAsyncThunk(
  "sendEmail",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`sales/send-email/${id}`, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update sales Status
export const updatesalesStatus = createAsyncThunk(
  "updatesalesStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`sales/${id}`, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Delete sales
export const deleteSales = createAsyncThunk(
  "deleteSales",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`sales-delete`, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const salesSlice = createSlice({
  name: "saless",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get sales List
      .addCase(getSalesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSalesList.fulfilled, (state, action) => {
        state.loading = false;
        state.getSalesListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getSalesList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Company List
      .addCase(getcompanyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getcompanyList.fulfilled, (state, action) => {
        state.loading = false;
        state.getcompanyListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getcompanyList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get sales
      .addCase(getOneSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneSales.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneSalesStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getOneSales.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Create Edit sales
      .addCase(createSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSales.fulfilled, (state, action) => {
        state.loading = false;
        state.createSalesStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createSales.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Send Email
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.sendEmailStatus.data = action.payload;
        state.error = false;
      })
      .addCase(sendEmail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Delete sales
      .addCase(deleteSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSales.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteSalesStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteSales.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update sales Status
      .addCase(updatesalesStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatesalesStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSalesStatusStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updatesalesStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = salesSlice;
export default reducer;
