import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import RippleLoader from "../../common/RippleLoader";
import { Login } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { loginApiAdmin } from "../../redux/auth/slice";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logingDataLoading } = useSelector((store) => ({
    logingDataLoading: store?.authData?.loading,
  }));

  const handleLogin = (values) => {
    dispatch(loginApiAdmin(values)).then((res) => {
      if (res.type === "loginApiAdmin/fulfilled") {
        navigate("/");
      }
    });
  };
  return (
    <>
      <div className={`h-screen overflow-hidden relative`}>
        {logingDataLoading ? (
          <RippleLoader />
        ) : (
          <div
            className={`flex items-center justify-center h-full  bg-no-repeat bg-cover bg-center ${
              logingDataLoading && ` hidden `
            } `}
          >
            <section className="w-full">
              <div className="max-w-xl w-full mx-auto">
                <div className="rounded-lg drop-shadow-lg   px-6 py-8 mx-4">
                  <div className={"mb-8"}>
                    <h1 className="sm:text-2xl text-lg font-opensans font-semibold mb-1 text-mainthemeBlack">
                      Welcome Back
                    </h1>
                  </div>
                  {/* Login flow */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember_me: false,
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Please Enter Email"),
                      password: Yup.string()
                        .required("Please Enter Password")
                        .min(
                          8,
                          "Password is too short - should be 8 chars minimum."
                        )
                        .matches(
                          /[a-zA-Z]/,
                          "Password can only contain Latin letters."
                        ),
                    })}
                    onSubmit={(values) => {
                      handleLogin(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="space-y-5">
                          {/* Email */}
                          <InputWithLabel
                            className={
                              errors.email && touched.email
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="email"
                            label="Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            errors={errors.email && touched.email}
                            errorsText={errors.email}
                          />
                          {/* password */}
                          <div>
                            <div className="mb-1">
                              <InputWithLabel
                                className={
                                  errors.password && touched.password
                                    ? ` input-error `
                                    : ` border `
                                }
                                idFromName="password"
                                label="Password"
                                type="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                errors={errors.password && touched.password}
                                errorsText={errors.password}
                              />
                            </div>
                            {/* forget password */}
                            <div className="flex justify-between">
                              <button
                                type="button"
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  id={"remember_me"}
                                  name={"Remember Me"}
                                  className="w-5 h-5 mr-2 accent-brown"
                                  checked={values.remember_me}
                                  onChange={() =>
                                    setFieldValue(
                                      "remember_me",
                                      !values.remember_me
                                    )
                                  }
                                />
                                <p className="text-black font-normal text-base opacity-80 font-Montserrat">
                                  Remember Me
                                </p>
                              </button>
                              <Link
                                to={"/reset-password"}
                                className=" font-sfPro text-base text-brown"
                              >
                                Forget password
                              </Link>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="w-full block text-dark_secondary_Grey bg-lightprimaryGrey text-center text-base border-2 border-transparent  hover:border-2  hover:text-white hover:bg-mainthemeBlack rounded transition uppercase duration-500 py-2 lg:px-7 px-4"
                          >
                            LogIn
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </section>
            <img src={Login} />
          </div>
        )}
      </div>
    </>
  );
};

export default Auth;
