import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Login Api Admin;
export const loginApiAdmin = createAsyncThunk(
  "loginApiAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/admin/login`, data);
      if (response) {
        sessionStorage.setItem("accessToken", response?.data?.data?.token);
        sessionStorage.setItem("profileComplete", `${response?.data?.data}`);
        sessionStorage.setItem("adminId", `${response?.data?.data?.id}`);
      }
      AxiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.data?.data?.token}`;
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// forget password
export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/forget-password`, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// New password
export const newPassword = createAsyncThunk(
  "newPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/reset-password`, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialStates,
  reducers: {
    setAuth: (state) => {
      state.loading = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login Api Admin
      .addCase(loginApiAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginApiAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.loginStatus.data = action.payload;
        state.error = false;
      })
      .addCase(loginApiAdmin.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Forget Password
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.forgetPasswordStatus.data = action.payload;
        state.error = false;
      })
      .addCase(forgetPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // New Password
      .addCase(newPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(newPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.newPasswordStatus.data = action.payload;
        state.error = false;
      })
      .addCase(newPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
