import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import { DefaultUser, SortDownArrow } from "../../assets/svg/AllSvg";
import { AddButton, SelectBox } from "../../components/micro";
import DebounceSearch from "../../common/DebounceSearch";
import Action from "../../common/Action";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/customtable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomer,
  getcompanyList,
  getCustomerList,
} from "../../redux/customer/slice";
import ConfirmationModel from "../../common/ConfirmationModel";

const Customer = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedComapny, setSelectedCompany] = useState(null);
  const [allCompanyArray, setAllCompanyListArray] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [customerIdToDelete, setCustomerIdToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Selector
  const {
    customersList,
    customersListLoading,
    companyList,
    companyListLoading,
  } = useSelector((store) => ({
    customersList: store?.customerData?.getCustomerListStatus?.data,
    customersListLoading: store?.customerData?.loading,
    // Comapny List
    companyList: store?.customerData?.getcompanyListStatus?.data,
    companyListLoading: store?.customerData?.getcompanyListStatus?.loading,
  }));
  useEffect(() => {
    dispatch(
      getCustomerList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        company_name: selectedComapny?.value ?? "",
        sort_by: sortBy,
        sort_direction: sortDirection === "asc" ? "asc" : "desc",
      })
    ).then((response) => {
      // Extract last_page from the API response and set it
      if (response?.payload?.data?.last_page) {
        setLastPage(response.payload.data.last_page);
      }
    });
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    selectedComapny,
    sortBy,
    sortDirection,
  ]);

  // company list
  useEffect(() => {
    dispatch(getcompanyList());
  }, []);

  useEffect(() => {
    if (companyList?.data) {
      let data = companyList?.data?.map((e) => ({ label: e, value: e }));
      setAllCompanyListArray(data);
    }
  }, [companyList]);

  //delete customer
  const deleteCustomerId = () => {
    setIsLoading(true);
    if (!customerIdToDelete) return;
    dispatch(deleteCustomer(customerIdToDelete)).then((res) => {
      if (res.type === "deleteCustomer/fulfilled") {
        dispatch(
          getCustomerList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            company_name: selectedComapny?.value ?? "",
            sort_by: sortBy,
            sort_direction: sortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
      setIsLoading(false);
      setConfirmDelete(false);
    });
  };

  //   table header
  const ColumnHeaders = () => (
    <>
      <th className="table-header">No.</th>
      <th className="table-header">
        <div
          className="flex items-center cursor-pointer gap-1"
          onClick={() => {
            setSortBy("full_name");
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          }}
        >
          <span>Name</span>
          <SortDownArrow />
        </div>
      </th>
      <th className="table-header">
        <div
          className="flex items-center cursor-pointer gap-1"
          onClick={() => {
            setSortBy("company_name");
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          }}
        >
          <span>Company</span>
          <SortDownArrow />
        </div>
      </th>
      <th className="table-header">Email</th>
      <th className="table-header">Mobile</th>
      <th className="table-header">Actions</th>
    </>
  );
  // Table Rows
  const DataRows = () => (
    <>
      {(customersList?.data.data || [])?.map((item, index) => (
        <tr
          key={item.id}
          className="font-Proxima shadow-sm hover:bg-shadedgrey border-b"
        >
          <td className="table-col">
            {(customersList?.data.current_page - 1) *
              customersList?.data.per_page +
              index +
              1}
          </td>
          <td className="table-col">{item?.full_name ?? "-"}</td>
          <td className="table-col">{item?.company_name ?? "-"}</td>
          <td className="table-col">{item?.email ?? "-"}</td>
          <td className="table-col">{item?.phone ?? "-"}</td>
          <td className="flex px-5 py-3">
            <Action
              optionList={
                <>
                  <li
                    className="action-button"
                    onClick={() =>
                      navigate("/view-customer", {
                        state: { customer: item, mode: "edit" },
                      })
                    }
                  >
                    View/Edit
                  </li>
                  <li
                    className="action-button"
                    onClick={() => {
                      setCustomerIdToDelete(item?.id);
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </li>
                </>
              }
            />
          </td>
        </tr>
      ))}
    </>
  );
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex-jb-ic ">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <h6 className="main-title">Customers</h6>
            </div>
            <AddButton
              value="Add Customer"
              onClick={() =>
                navigate("/create-edit-customer", {
                  state: { mode: "create" },
                })
              }
            />
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Customers"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="flex gap-2 bg-white border rounded-md border-secondaryWhite p-4">
          <SelectBox
            className={"w-full sm:max-w-52 sm:min-w-52 max-w-36 min-w-36 p-0"}
            id="Company"
            placeholder="Company"
            isLoading={companyListLoading}
            isClearable={true}
            options={allCompanyArray}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            onChange={(e) => {
              setSelectedCompany(e);
            }}
            paddingY={"3px"}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #0000003B`,
                borderRadius: "0.3rem",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #0000003B",
                },
              }),
            }}
          />
          <DebounceSearch
            className="w-full"
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search by title"}
          />
        </div>
        <div className="table-layout">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={customersList?.data}
            loading={customersListLoading}
            overflowVisibleLg={true}
            showPagination={true}
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            total={customersList?.data?.total}
            current={pageCount}
            lastPage={lastPage}
            paginationData={(crPage) => setPageCount(crPage)}
            isLoading={isLoading}
          />
        </div>
      </div>
      {confirmDelete && (
        <div>
          <ConfirmationModel
            confirmText={"Delete"}
            onClose={() => {
              setConfirmDelete(false);
            }}
            onConfirm={deleteCustomerId}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default Customer;
