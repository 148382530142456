import React, { useEffect, useState } from "react";
import ConfirmationModel from "../../common/ConfirmationModel";
import {
  DefaultButton,
  DefaultPrimaryButton,
  DeleteButton,
  InputError,
  InputWithLabel,
  PrimaryAddButton,
} from "../../components/micro";
import FileUploadChoose from "../../common/FileUploadChoose";
import Toggles from "../../common/Toggles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SettingVector } from "../../assets/svg/AllSvg";
import { createPackages, getPackage } from "../../redux/package/slice";
import { uploadFile } from "../../redux/uploadFile/slice";
import RippleLoader from "../../common/RippleLoader";

const CreateEditPackage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEditMode = location?.state?.mode;
  const [formValues, setFormValues] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const packageData = location?.state?.package;

  //  Selector
  const { getpackageData, loading } = useSelector((store) => ({
    getpackageData: store?.packageData?.getPackageStatus?.data?.data,
    loading: store?.packageData?.loading,
  }));

  // create package
  const handleConfirm = () => {
    setIsLoading(true);
    const payload = {
      name: formValues?.packageName,
      price: formValues?.price_to_be_advised ? 0 : formValues?.pricing,
      price_to_be_advised: formValues?.price_to_be_advised,
      require_quantity: formValues?.require_quantity,
      description: formValues?.productDescription,
      items: itemsArray,
      images: isEditMode === "edit" ? imageUrls : imageUrls,
    };
    const action = packageData?.id
      ? createPackages({ id: packageData.id, ...payload })
      : createPackages(payload);

    dispatch(action)
      .then((res) => {
        if (res.type === "createPackages/fulfilled") {
          setIsLoading(false);
          setShowConfirmationModal(false);
          navigate("/package");
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // Get File URL Upload Editor
  useEffect(() => {
    if (packageData?.images?.length) {
      const uploadeFiles = packageData?.images?.map((element) => {
        return element?.document?.url;
      });
      setUploadedFiles(uploadeFiles);
      setImageUrls(uploadeFiles);
    }
  }, [isEditMode, packageData]);
  // file upload
  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    const newUploadedFile = URL.createObjectURL(file);
    setUploadedFiles([newUploadedFile]);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "package");

    setIsFileUpload(true);

    setIsFileUpload(true);
    const response = await dispatch(uploadFile(formData));
    setIsDisable(false);
    setIsFileUpload(false);
    if (response.type === "uploadFile/fulfilled") {
      setFieldValue("uploadedFiles", response?.payload?.data.id);
      setImageUrls([response.payload.data.id]);
      setIsDisable(false);
      return response?.payload?.data?.id;
    }
  };

  // get package
  useEffect(() => {
    if (packageData?.id) {
      dispatch(getPackage(packageData?.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode === "edit" && packageData) {
      if (packageData?.items?.length) {
        const GetItemsArray = packageData?.items?.map((e) => {
          return e?.name || e;
        });
        setItemsArray([...GetItemsArray]);
      }
    }

    if (isEditMode === "edit" && packageData) {
      if (packageData?.images?.length) {
        const imgArray = packageData?.images?.map((e) => {
          return e?.document_id;
        });
        setImageUrls(imgArray);
      }
    }
  }, []);

  const handleAddItem = (item, setFieldValue) => {
    if (item.trim()) {
      const updatedItems = [...itemsArray, item];
      setItemsArray(updatedItems);
      setFieldValue("packageitems", updatedItems);
    }
  };
  // Delete package
  const handleDelete = (setFieldValue) => {
    setUploadedFiles([]);
    setFieldValue("uploadedFiles", "");
  };
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex-jb-ic ">
            <div className="flex items-center gap-2">
              <SettingVector />
              <h6 className="main-title">Packages Setting</h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={
              isEditMode === "edit" ? "Edit Package" : "Create Package"
            }
            breadCrumbParent2={"Package"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        {loading ? (
          <RippleLoader />
        ) : (
          <div className="bg-white shadow-md rounded-lg p-6">
            <h6 className=" md:text-xl font-normal mb-3">
              {isEditMode === "edit" ? "Edit Package" : "Create Package"}{" "}
            </h6>
            <Formik
              enableReinitialize={true}
              initialValues={{
                packageName:
                  formValues?.packageName ??
                  (packageData?.id ? getpackageData?.name : ""),
                pricing:
                  formValues?.pricing ??
                  (packageData?.id ? getpackageData?.price : ""),
                productDescription:
                  formValues?.productDescription ??
                  (packageData?.id ? getpackageData?.description : ""),
                packageitems:
                  formValues?.packageitems ??
                  (packageData?.id ? getpackageData?.items : []),
                price_to_be_advised:
                  formValues?.price_to_be_advised ??
                  (packageData?.id
                    ? getpackageData?.price_to_be_advised === 1
                    : false),
                require_quantity:
                  formValues?.require_quantity ??
                  (packageData?.id
                    ? getpackageData?.require_quantity === 1
                    : false),
                addpackageitems: "",
                uploadedFiles:
                  formValues?.uploadedFiles ??
                  (packageData?.id && getpackageData?.images?.length
                    ? getpackageData?.images[0]?.document?.url
                    : ""),
              }}
              validationSchema={Yup.object().shape({
                packageName: Yup.string()
                  .trim()
                  .nullable()
                  .required("Please Enter Name"),
                pricing: Yup.number().when("price_to_be_advised", {
                  is: false,
                  then: Yup.number()
                    .required("Please Enter Price")
                    .min(0, "Price must be a positive number"),
                  otherwise: Yup.number().notRequired(),
                }),
                packageitems: Yup.array().min(
                  1,
                  "Please add at least one package item"
                ),
                productDescription: Yup.string()
                  .trim()
                  .nullable()
                  .required("Please Enter Description"),
                uploadedFiles: Yup.string().required("Please Upload Image"),
              })}
              onSubmit={(values) => {
                setFormValues(values);
                setShowConfirmationModal(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="sm:grid grid-cols-2 gap-6 mb-6">
                    {/* Product Name */}
                    <div className="sm:mb-0 mb-2">
                      <InputWithLabel
                        idFromName="packageName"
                        label="Package Name*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.packageName}
                        errors={errors.packageName && touched.packageName}
                        errorsText={errors.packageName}
                        className={`${"input-css "}  ${
                          errors.packageName && touched.packageName
                            ? `border border-red`
                            : `  `
                        }  `}
                      />
                    </div>
                    {/* Pricing */}
                    <InputWithLabel
                      idFromName="pricing"
                      label="Pricing ($)*"
                      type="numeric"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleChange(e);
                          setIsDisable(false);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.pricing}
                      errors={errors.pricing && touched.pricing}
                      errorsText={errors.pricing}
                      disabled={values.price_to_be_advised}
                      className={`${
                        values.price_to_be_advised
                          ? " text-gray-500 cursor-not-allowed border-dashed border-secondary_grey"
                          : "input-css "
                      }  ${
                        errors.pricing && touched.pricing
                          ? `border border-red`
                          : ``
                      } `}
                    />
                  </div>
                  {/* Toggle Switch */}
                  <div className="">
                    <div className="flex items-center mb-3">
                      <label className="flex items-center cursor-pointer">
                        <Toggles
                          checked={values.price_to_be_advised}
                          value={values.price_to_be_advised}
                          toggleFor={"price_to_be_advised"}
                          onChange={(e) => {
                            setIsDisable(false);
                            const isChecked = e.target.checked;
                            setFieldValue("price_to_be_advised", isChecked);
                          }}
                        />
                        <span className="ml-2 sm:text-base text-sm font-Proxima">
                          Price to be advised
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* Toggle Switch Require Quantity*/}
                  <div className="grid grid-cols-2 gap-6 ">
                    <div className="flex items-center mb-6">
                      <label className="flex items-center cursor-pointer">
                        <Toggles
                          checked={values.require_quantity}
                          value={values.require_quantity}
                          toggleFor={"require_quantity"}
                          onChange={(e) => {
                            setIsDisable(false);
                            const isChecked = e.target.checked;
                            setFieldValue("require_quantity", isChecked);
                          }}
                        />

                        <span className="ml-2 sm:text-base text-sm font-Proxima">
                          Require Quantity
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* Product Description */}
                  <InputWithLabel
                    idFromName="productDescription"
                    label="Product Description*"
                    type="textarea"
                    onChange={(e) => {
                      handleChange(e);
                      setIsDisable(false);
                    }}
                    onBlur={handleBlur}
                    value={values.productDescription}
                    errors={
                      errors.productDescription && touched.productDescription
                    }
                    errorsText={errors.productDescription}
                    className={`${"input-css "}  ${
                      errors.productDescription && touched.productDescription
                        ? `border border-red`
                        : ``
                    }  `}
                    inputClass="additional-input-classes"
                  />

                  {/* Display Package Items */}
                  <div>
                    {(itemsArray || packageData?.items)?.map((item, index) => (
                      <div className="mt-3 flex items-center gap-2" key={index}>
                        <div className="w-full border px-3  rounded-md">
                          <InputWithLabel
                            idFromName="item"
                            label="Item"
                            type="text"
                            value={item || item.name}
                            onChange={(e) => {
                              const updatedItems = [...itemsArray];
                              updatedItems[index] = e.target.value;
                              setItemsArray(updatedItems);
                              setIsDisable(false);
                            }}
                            inputClass="additional-input-classes"
                          />
                        </div>
                        <DeleteButton
                          value="Delete"
                          className={""}
                          onClick={() => {
                            setItemsArray((prev) =>
                              prev.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </div>
                    ))}

                    <div className="mt-3 flex items-center gap-2">
                      <div className="w-full">
                        <InputWithLabel
                          idFromName="addpackageitems"
                          label="Package Items*"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            setIsDisable(false);
                          }}
                          onClick={() => {
                            handleAddItem(
                              values.addpackageitems,
                              setFieldValue
                            );
                            setFieldValue("addpackageitems", "");
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleAddItem(
                              values.addpackageitems,
                              setFieldValue
                            );
                            setFieldValue("addpackageitems", "");
                          }}
                          value={values.addpackageitems}
                          className="input-css  "
                          inputClass="additional-input-classes"
                        />
                      </div>
                      <PrimaryAddButton
                        className={"whitespace-nowrap"}
                        value="Add Item"
                        onClick={() => {
                          handleAddItem(values.addpackageitems, setFieldValue);
                          setFieldValue("addpackageitems", "");
                        }}
                      />
                    </div>
                    {itemsArray.length === 0 &&
                      errors.packageitems &&
                      touched.packageitems && (
                        <InputError errorTitle={errors.packageitems} />
                      )}
                  </div>

                  {/* Product Image Upload */}
                  <div className="mb-4 mt-4">
                    <label className="block text-sm font-medium mb-2 text-gray-dark font-Proxima">
                      Product Image
                    </label>
                    {isFileUpload ? (
                      <RippleLoader />
                    ) : (
                      <>
                        <FileUploadChoose
                          className={`${"input-css "}  ${
                            errors.uploadedFiles && touched.uploadedFiles
                              ? `border border-red`
                              : ``
                          }  `}
                          handleFileChange={(e) => {
                            handleFileChange(e, setFieldValue);
                            setIsDisable(false);
                          }}
                          uploadedFiles={uploadedFiles}
                          onDelete={() => handleDelete(setFieldValue)}
                        />
                        {errors.uploadedFiles && touched.uploadedFiles ? (
                          <InputError errorTitle={errors.uploadedFiles} />
                        ) : null}
                      </>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-end mt-3">
                    <div className="flex gap-2">
                      <DefaultPrimaryButton
                        value="Cancel"
                        onClick={() => navigate("/package")}
                      />
                      <DefaultButton
                        type="submit"
                        value={isEditMode === "edit" ? "Save" : "Create"}
                        disabled={isDisable}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      {showConfirmationModal && (
        <div>
          <ConfirmationModel
            confirmText={isEditMode === "edit" ? "Edit" : "Create"}
            onClose={() => setShowConfirmationModal(false)}
            onConfirm={() => handleConfirm()}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
export default CreateEditPackage;
