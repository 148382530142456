import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Customer List
export const getCustomerList = createAsyncThunk(
  "getCustomerList",
  async (
    { search, pageLimit, pageCount, sort_by, sort_direction, company_name },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `customers?&sort_by=${sort_by}&sort_direction=${sort_direction}&company_name=${company_name}&search=${search}&page=${pageCount}&limit=${pageLimit}`
      );
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response.data);
    }
  }
);

//Get Company List
export const getcompanyList = createAsyncThunk(
  "getcompanyList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("companies");
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// Get Customer
export const getCustomer = createAsyncThunk(
  "getCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`/customers/${id}`);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Create Edit Customer
export const createCustomers = createAsyncThunk(
  "createCustomers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`customers`, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update Customer Status
export const updateCustomerStatus = createAsyncThunk(
  "updateCustomerStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`Customers/${id}`, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// Delete Customer
export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`/customers/${id}`);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      if (!error) {
        throw error?.response;
      }
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Customer List
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomerListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCustomerList.rejected, (state) => {
        state.getCustomerListStatus.loading = false;
        state.error = true;
      })
      // Get Customer
      .addCase(getCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCustomer.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Company List
      .addCase(getcompanyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getcompanyList.fulfilled, (state, action) => {
        state.loading = false;
        state.getcompanyListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getcompanyList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Edit Customer
      .addCase(createCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.createCustomersStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createCustomers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Delete Customer
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteCustomerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update Customer Status
      .addCase(updateCustomerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCustomerStatusStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateCustomerStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = customersSlice;
export default reducer;
