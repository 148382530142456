import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FileUploadBack, SettingVector } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../../redux/package/slice";
import RippleLoader from "../../common/RippleLoader";
import { InfoRow } from "../../components/micro";

const ViewPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const packageData = state?.package;

  const { getpackageData, loading } = useSelector((store) => ({
    getpackageData: store?.packageData?.getPackageStatus?.data?.data,
    loading: store?.packageData?.loading,
  }));

  useEffect(() => {
    if (packageData?.id) dispatch(getPackage(packageData.id));
  }, [dispatch, packageData?.id]);

  if (loading) return <RippleLoader />;

  return (
    <div className="space-y-5">
      {/* Header Section */}
      <div className="mb-3">
        <div className="flex-jb-ic">
          <div className="flex items-center gap-2">
            <SettingVector />
            <h6 className="main-title">Customers</h6>
          </div>
        </div>
        <Breadcrumb
          breadCrumbParent="Home"
          breadCrumbParent2="Packages Setting"
          breadCrumbActive="Edit Package"
          breadCrumbTitleKey="/"
        />
      </div>
      {/* Package Details Section */}
      <div className="bg-lightGrey p-6 rounded-lg">
        <div className="flex-jb-ic border-b border-b-secondary_grey pb-5 mb-4">
          <div className="flex items-center gap-2">
            <SettingVector />
            <p className="text-xl font-normal text-primary_black">
              Package Details
            </p>
          </div>
          <p
            className="text-mainthemeBlack border-b border-b-primaryBlack cursor-pointer"
            onClick={() =>
              navigate("/create-edit-package", {
                state: { package: packageData, mode: "edit" },
              })
            }
          >
            Edit
          </p>
        </div>
        {/* Package Images */}
        <div className="flex gap-5">
          {getpackageData?.images?.length > 0 ? (
            getpackageData?.images?.map((item, index) => (
              <div className="sm:w-[338px] sm:h-[240px]" key={index}>
                <img
                  src={item?.document?.url}
                  alt={item?.document?.description || "Image description"}
                  className="w-[338px] h-[240px] object-cover"
                />
              </div>
            ))
          ) : (
            <FileUploadBack />
          )}
        </div>
        {/* Package Info */}
        <div className="space-y-4 mt-4">
          <InfoRow
            label="Pricing"
            value={
              getpackageData?.price === 0
                ? "To Be Advised"
                : `$${getpackageData?.price}`
            }
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InfoRow label="Package Name" value={getpackageData?.name} />
            <InfoRow
              label="Package Items"
              value={
                <ul className="list-disc pl-5">
                  {getpackageData?.items?.map((item, index) => (
                    <li key={index}>{item?.name}</li>
                  ))}
                </ul>
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InfoRow
              label="Package Details"
              value={getpackageData?.description}
            />
            <InfoRow
              label="Require Quantity"
              value={getpackageData?.require_quantity === 1 ? "Yes" : "No"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewPackage;
