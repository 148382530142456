import React, { useEffect } from "react";
import { DefaultUser } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../redux/customer/slice";
import RippleLoader from "../../common/RippleLoader";
import { InfoRow } from "../../components/micro";

const ViewCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const customerData = state?.customer;

  const { getCustomerData, loading } = useSelector((store) => ({
    getCustomerData: store?.customerData?.getCustomerStatus?.data?.data,
    loading: store?.customerData?.loading,
  }));

  useEffect(() => {
    if (customerData?.id) dispatch(getCustomer(customerData.id));
  }, [dispatch, customerData?.id]);

  if (loading) return <RippleLoader />;

  return (
    <div className="space-y-5">
      <div className="mb-3">
        <div className="flex-jb-ic">
          <div className="flex items-center gap-2">
            <DefaultUser />
            <h6 className="main-title">Customers</h6>
          </div>
        </div>
        <Breadcrumb
          breadCrumbParent="Home"
          breadCrumbParent2="Customers"
          breadCrumbActive="Edit Customers"
          breadCrumbTitleKey="/"
        />
      </div>
      {/* Customer Details Section */}
      <div className="bg-lightGrey p-6 rounded-lg">
        <div className="flex-jb-ic border-b border-b-secondary_grey pb-5 mb-4">
          <div className="flex items-center gap-2">
            <DefaultUser />
            <p className="text-xl font-normal text-primary_black">
              Customer Details
            </p>
          </div>
          <p
            className="text-mainthemeBlack border-b border-b-primaryBlack cursor-pointer"
            onClick={() =>
              navigate("/create-edit-customer", {
                state: { customer: customerData, mode: "edit" },
              })
            }
          >
            Edit
          </p>
        </div>
        {/* Customer Info */}
        <div className="space-y-4">
          <InfoRow label="Customer Name" value={getCustomerData?.full_name} />
          <InfoRow label="Company Name" value={getCustomerData?.company_name} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InfoRow
              label="Office Number"
              value={
                getCustomerData?.office_number
                  ? `+${getCustomerData.office_number}`
                  : "-"
              }
            />
            <InfoRow
              label="Mobile Number"
              value={getCustomerData?.phone ? `+${getCustomerData.phone}` : "-"}
            />
          </div>
          <InfoRow label="Email" value={getCustomerData?.email} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InfoRow label="Address" value={getCustomerData?.address} />
            <InfoRow label="Postal Code" value={getCustomerData?.postal_code} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCustomer;
