import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const Pagination = ({ total, lastPage, current, pagination }) => {
  const handlePagination = (newPage) => {
    pagination(newPage);
  };

  const isNextDisabled = current === lastPage; // Use passed lastPage prop
  const isPrevDisabled = current === 1;

  return (
    <div>
      <ul className="flex items-center justify-center gap-3 rounded-full p-1.5">
        {/* Previous Button */}
        <li className="page-item">
          <Link
            to={"#"}
            className={`flex items-center justify-center rounded-full }`}
            onClick={() => !isPrevDisabled && handlePagination(current - 1)}
          >
            <span className="xl:text-lg text-base font-bold mr-1 pt-0.5">
              <ArrowLeft2 size="16" />
            </span>
          </Link>
        </li>

        {/* Next Button */}
        <li className="page-item">
          <Link
            to={"#"}
            className={`flex items-center justify-center rounded-full ${
              isNextDisabled ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={() => !isNextDisabled && handlePagination(current + 1)}
          >
            <span className="xl:text-lg text-base font-bold pt-0.5">
              <ArrowRight2 size="16" />
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
